import { Widget } from "./baseWidget";

export class Duration extends Widget {
  type = 'duration';
  gridX = 6;
  minGridX = 3;
  config = {
    props: [
      // "placeholder",
      'tooltip',
      'viewOnly',
      "required",
    ],
    // placeholder: {
    //   displayName: "Duration Placeholder",
    //   value: "Enter duration",
    //   type: 'text'
    // },
    tooltip: {
      displayName: 'Tooltip',
      value: "",
      type: 'text',
      enabled: true
    },
    required: {
      displayName: 'Required',
      type: 'boolean',
      value: false,
    },
    viewOnly: {
      displayName: 'View Only Mode',
      description: 'When turned on, user can not change the duration value',
      value: false,
      type: 'boolean',
      resetValue: false
    },
  }

  constructor(id: number, name: string) {
    super(id, name)
    this.attachCommonConfig(this)
    this.config['title'].value = 'Enter duration'
  }
}
