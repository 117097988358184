import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConnectionService } from 'src/app/modules/organization/connection.service';
import { AuthServiceService } from 'src/app/shared/services/auth-service.service';
import { StarchService } from 'src/app/shared/services/starch.service';
import { LocaleService } from 'src/app/shared/services/translate.service';
import { SpinnerService } from 'src/app/shared/spinner/spinner.service';
import { FormService } from '../form.service';
import { WidgetService } from 'src/app/bloom/services/widget-service.service';
import { Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MatTabGroup } from '@angular/material/tabs';
import html2canvas from 'html2canvas';
import { ScreenshotService } from 'src/app/core/services/ScreenshotService';
import { FormThemesComponent } from '../form-themes/form-themes.component';
import { ResizeService } from 'src/app/bloom/page/panel/service/resize.service';


@Component({
  selector: 'app-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit {
  @ViewChild('tabGroup') tabGroup: MatTabGroup;
  @ViewChild('tabGroupSidebar') tabGroupSidebar: MatTabGroup;
  workspaceReady: boolean = false
  // spinner:boolean = false;
  authError: boolean;
  // formCode: string;
  // formMeta: any
  // bloomCode: any
  // pageNames: any = []
  ready: boolean = false

  sectionsToHide: any[] = ["charts", "connections", "panels", "starch"]
  isBrowser: any;
  showResponse: boolean;
  showAutomation: boolean;
  formStyle: any;
  pageSelectSubscription: any;
  @ViewChild('buildercanvas') buildercanvas!: ElementRef;
  @ViewChild('themesSettingsComponent') themesSettingsComponent: FormThemesComponent;

  isSidebarCollapsed = false;
  settingsTabDisabled: boolean = true

  widgetConfigOpenSub: any;
  widgetDeselectedSub: any;
  capturePageScreenShots: any;
  page: any;
  screenCaptured: boolean;

  constructor(
    private dialog: MatDialog,
    private spinnerService: SpinnerService,
    private route: ActivatedRoute,
    public router: Router,
    public snackBar: MatSnackBar,
    public starchService: StarchService,
    public authService: AuthServiceService,
    public connectionService: ConnectionService,
    private _ngZone: NgZone,
    private localeService: LocaleService,
    private translate: TranslateService,
    public formService: FormService,
    private cdr: ChangeDetectorRef,
    private widgetService: WidgetService,
    private el: ElementRef,
    private screenshotService: ScreenshotService,
    private renderer: Renderer2,
    private resizeService: ResizeService,
    @Inject(PLATFORM_ID) platformId?: Object
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
    if(!this.isBrowser) return;
    this.translate.use(this.localeService.currentLocale);

    // router.events.pipe(
    //   filter(event => event instanceof NavigationEnd)
    // )
    //   .subscribe((event: any) => {
    //     let url = decodeURI(event.url)
    //     console.log("[FORM BUILDER] constructor(): url", url)
    //     let pathParts = url.split('/')
    //     if (pathParts[1] == 'form' && pathParts[2] == 'build') {
    //       this.formCode = pathParts[3]
    //     }
    //     console.log("[BUILDER] constructor(): formCode", this.formCode)
    //   });

    this.renderer.listen('document', 'click', (event) => {
      this.handlePageClick(event);
    });
  }

  async ngOnInit() {
    this.authService.subproduct = "form";
    this.spinnerService.hide()
    this.formService.selectedPage = null;
    if (!this.authService.loggedIn) {
      this.authService.authCheck()
      this.authService.authCheckPositive.subscribe(res => {
        console.log("auth check result", res);
        window.dispatchEvent(new Event('resize'));
        this.connectionService.getPreAuthenticatedToken();
        this.connectionService.getWorkSpaceId()
        if (res) {
          // this.loadForm()
          this.ready = true
        } else {
          console.log("navigating to login page")
          this._ngZone.run(() => {
            this.router.navigate(['../../'], { relativeTo: this.route })
          })
        }
      })
    } else {
      // this.loadForm()
      // ready
      this.ready = true
    }

    this.formService.themeSubject.subscribe(theme => {
      if(!theme || Object.keys(theme).length == 0) return;
      this._ngZone.run(() => {
        this.formStyle = theme;
        this.applyStyles()
        this.cdr?.detectChanges();
      })

    });

    // this.userChangesSubscriptionForms = this.formService.$userMadeChanges.subscribe(isChanged => {
      // if(this.page?.code && !this.screenCaptured && this.formService.builderMode) this.captureScreen(this.page);
    // })

    this.capturePageScreenShots = this.formService.$capturePageScreenShots.subscribe((e) => {
      if(e) this.captureScreen()
    })

    this.pageSelectSubscription = this.formService.pageSelectionRequest.subscribe(async (page) => {
      if(!page?.code) return;
      this.page = page;
      if(page.code == "introduction"){
        this.sectionsToHide = ["standard", "dateTime", "media", "charts", "connections", "panels", "starch"];
      } else this.sectionsToHide = ["charts", "connections", "panels", "starch"];

      if(this.showResponse || this.showAutomation) { //Draft
        this.showResponse = false;
        this.showAutomation = false;
        this.tabGroup.selectedIndex = 0;
      }
    })

    this.widgetConfigOpenSub = this.widgetService.$openWidgetSidebarSettings.subscribe(data => {
      if(!data) return
      console.log("widget config open request", JSON.parse(JSON.stringify(data)))

      if(this.isSidebarCollapsed) this.isSidebarCollapsed = false
      console.log("isSidebarCollapsed", this.isSidebarCollapsed)

      // change tab to settings
      this.settingsTabDisabled = false
      this.tabGroupSidebar.selectedIndex = 3 // focus to settings tab
      this.tabGroupSidebar.realignInkBar()
    })

    this.widgetDeselectedSub = this.widgetService.$widgetDeselected.subscribe(data => {
      this.settingsTabDisabled = true
      this.tabGroupSidebar.selectedIndex = 0
      this.widgetService.openWidgetSidebarSettings.next(null)
      this.tabGroupSidebar.realignInkBar()
    })
  }

  handlePageClick(event: MouseEvent): void {
    this.resizeService.resizeMouseUpX(event);
    this.resizeService.resizeMouseUpY(event);
    this.resizeService.resizeMouseUpZ(event);
  }

  toggleSidebar() {
    console.log("isSidebarCollapsed before toggle", this.isSidebarCollapsed)
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
    console.log("isSidebarCollapsed after", this.isSidebarCollapsed)
  }

  getMargin(margin){
    let marginCss = {};
    let marginLeft = margin?.left || 15;
    let marginRight = margin?.right || 85;
    let marginTop = margin?.top || 2;
    let marginBottom = margin?.bottom || 98;
    marginCss = {
      "margin-left": (marginLeft) + "%",
      "margin-right": (100 - marginRight) + "%",
      "margin-top": (marginTop) + "%",
      "margin-bottom": (100 - marginBottom) + "%",
    }
    return marginCss;
  }

  getModifiedStyle(style: any) {
    if (!style) return {};
    let modifiedStyle = { ...style };
    let marginStyles = this.getMargin(this.formStyle?.frameconfig?.margin)
    if (marginStyles['margin-top']) {
        modifiedStyle['padding-top'] = this.convertToPercentage(marginStyles['margin-top']);
    }
    if (marginStyles['margin-bottom']) {
      modifiedStyle['padding-bottom'] = this.convertToPercentage(marginStyles['margin-bottom']);
  }
    return modifiedStyle;
  }

  convertToPercentage(value: string): string {
    const trimmedValue = parseFloat(value.trim()); // Remove any units and spaces
    const percentage = (trimmedValue / 100) * 50; // Convert to a smaller percentage (50% of the original)
    return `${percentage}%`;
  }


  getMergedStyles(): { [key: string]: string } {
    const marginStyles = this.getMargin(this.formStyle?.frameconfig?.margin);
    const frameStyles = this.formStyle?.frameconfig?.style && !this.showResponse && !this.showAutomation
                         ? this.formStyle.frameconfig.style
                         : {};

    return {
      ...marginStyles,
      ...frameStyles
    };
  }

  applyStyles() {
    const element = this.el.nativeElement.querySelector('.canvas-wrap');
    const frameelement = this.el.nativeElement.querySelector('.form-canvas');
    if (element && this.formStyle.style) {
      Object.keys(this.formStyle.style).forEach(style => {
        this.renderer.setStyle(element, style, this.formStyle.style[style]);
      });
    }
    if (frameelement && this.formStyle?.frameconfig?.style) {
      Object.keys(this.formStyle.frameconfig.style).forEach(style => {
        this.renderer.setStyle(frameelement, style, this.formStyle.frameconfig.style[style]);
      });
    }

    // this.formService.formMeta.next(form); // Update the form meta with the new widget
    // this.formService.userMadeChanges.next(true);
  }

  async initialize(){
    // console.log("form in auth", this.connectionService.workSpaceId);
    await this.connectionService.getWorkSpaceId();
    this.workspaceReady = true;

  }

  async captureScreen() {
    this.formService.capturedScreenshots.inProgress = true;
    let form = this.formService?.formMeta?.value;
    if(!form?.sections || form.sections?.length == 0){
      form.sections = [];
      form.sections[0] = {
        code: "main",
        name: "Main",
        noDrag: true,
        panels : form.panels
      }
    }
    this.formService.capturedScreenshots.screenshots = {};
    this.formService.capturedScreenshots.banner = "";
    for (let index = 0; index < form.sections.length; index++) {
      let page = form.sections[index];
      this.formService.pageSelectionRequest.next(page);
      // await sleep(100);
      const element = this.buildercanvas?.nativeElement;
      element.style.visibility = 'visible';
      if(!element) return
      try {
        let fileName = `form/template_data/${this.formService?.formMeta?.value?.code}-${"" + Date.now()}`
        let result = await this.screenshotService.captureScreen(element, fileName);
        this.formService.capturedScreenshots.screenshots[page?.code] = result;
      } catch (e){
        console.error("error on capturing screenshots", e)
      } finally{}
    }
    this.formService.capturedScreenshots.inProgress = false;

    // this.screenCaptured = true;;
    // const element = this.buildercanvas?.nativeElement;
    // if(!element) return
    // try {
    //   let fileName = `form/template_data/${this.formService?.formMeta?.value?.code}-${"" + Date.now()}`
    //   let result = await this.screenshotService.captureScreen(element, fileName);
    //   this.formService.capturedScreenshots.screenshots[page?.code] = result;
    //   console.log("this.formService.capturedScreenshots", this.formService.capturedScreenshots)
    // } catch (e){
    //   console.log("error on capturing screenshots", e)
    // } finally{
    //   this.screenCaptured = false;
    // }
  }


  async tabChanged(data){
    console.log("tab changed", data)
    this.showResponse = false;this.showAutomation = false;
    if(data.index == 0) { //Draft
    } else if(data.index == 1) { //Responses
      this.showResponse = true;
      // this.formService.themeSubject.next({});
    } else if(data.index == 2) { //Automation
      this.showAutomation = true;

    }
  }

  async sideTabChanged(data){
    console.log("side tab changed", data);
    if(data.index == 2){
      this.themesSettingsComponent.componentInits()
    }
  }

  refreshList(){

  }

  ngOnDestroy(): void {
    this.authService?.authCheckPositive?.unsubscribe();
    this.pageSelectSubscription?.unsubscribe()
  }

  // async loadForm(){
  //   console.log("[loadForm] form code: ", this.formCode)
  //   let res = await this.formService.getFormByCode(this.formCode)
  //   console.log("form loaded", res)
  //   this.ready = true
  // }

  createPanel(widgetType: string) {
    console.log("create request at builder component", widgetType)
    this.widgetService.widgetNotifier(widgetType)
  }

  formCanvasClick() {
    console.log("form canvas click")
    this.formService.closeContextMenuRequest.next(true)
  }

}

// Utility function to sleep
function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
