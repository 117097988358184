export const CommonConfig: any = {
  
  properties: ['backgroundColor', 'description', 'title'],

  backgroundColor: {
    supportedWidgets: [
      'input', 'textarea', 'imageinput', 'checkbox', 'datetime', 'date', 'time', 'richtext', 
      'select', 'choice', "chips", "autocomplete", 'numberinput', 'slider', 'period', 'duration', 'star',
      'label', 'button', 'chart', 'embed', 'icon', 'image', 'link', 'tags'
    ],
    config: {
      displayName: 'Background Color',
      type: 'color-picker',
      value: 'transparent',
    }
  },

  title: {
    // supportedWidgets: ['input', 'textarea',  'checkbox','select', 'choice', "chips", "autocomplete"],
    supportedWidgets: [
      'input', 'textarea', 'imageinput', 'checkbox', 'datetime', 'date', 'time', 'richtext', 
      'select', 'choice', "chips", "autocomplete", 'numberinput', 'slider', 'period', 'duration', 'star', 'tags'
    ],
    config: {
      displayName: "Title",
      value: '',
      type: 'text',
      show: true
    },
  },

  description: {
    // supportedWidgets: ['input', 'textarea',  'checkbox','select', 'choice', "chips", "autocomplete"],
    supportedWidgets: ['input', 'textarea', 'imageinput', 'checkbox', 'datetime', 'date', 'time', 'richtext', 
      'select', 'choice', "chips", "autocomplete", 'numberinput', 'slider', 'period', 'duration', 'star'],
    config: {
      displayName: "Description",
      value: "",
      type: 'text'
    },
  }
}

export const AvailableOptions: any = {
  appearance: {
    widgetsSupported: ["input", "numberinput", "select", 'autocomplete', "textarea", "connection", "connection-list", "date", "datetime", "period", "time"],
    availableOptions: [
      "standard",
      "outline"
    ]
  },
  orientation: {
    widgetsSupported: ["checkbox", "choice"],
    availableOptions: [
      "vertical",
      "horizontal"
    ]
  },
  buttonType: {
    widgetsSupported: ["button"],
    availableOptions: [
      "Basic",
      "Raised",
      "Stroked",
      "Flat",
      // "Icon",
      "Fab",
      // "Mini-fab"
    ]
  },
  buttonColorType: {
    widgetsSupported: ["button"],
    availableOptions: [
      "primary",
      "accent",
      "warn",
      "none",
      "custom"
    ]
  },
  tagType: {
    widgetsSupported: ["tags"],
    availableOptions: [
      "Basic",
      "Raised",
      "Stroked",
      "Flat"
    ]
  },
  tagColorType: {
    widgetsSupported: ["tags"],
    availableOptions: [
      "primary",
      "accent",
      "warn"
    ]
  },
  width: {
    widgetsSupported: ["image"],
    availableOptions: [
      "10%",
      "20%",
      "30%",
      "40%",
      "50%",
      "60%",
      "70%",
      "80%",
      "90%",
      "100%",
    ]
  },
  imageAlignment: {
    widgetsSupported: ["image"],
    availableOptions: [
      "left",
      "center",
      "right"
    ]
  },
}
