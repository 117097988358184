<div *ngIf="builderMode">
  <ng-container *ngIf="!editingHeading; else elseTemplate">
    <div class="editHeadingBlock" fxLayout="row wrap" [style.justify-content]="widgetMeta?.config?.alignment?.value" fxLayoutGap=".5rem">
      <div [class.blurred]="!widgetMeta.config.title.show" [ngStyle]="styles">{{ widgetMeta.config.title.value }}</div>
      <mat-icon [class.blurred]="!widgetMeta.config.title.show" class="icon editHeadingIcon" (click)="editingHeading = widgetMeta.config.title.show ? true : false; $event.stopPropagation()" matTooltip="Edit Title">edit</mat-icon>
      <mat-icon class="icon" *ngIf="widgetMeta.config.title.show" (click)="widgetMeta.config.title.show = false; titleVisibilityChanged(); $event.stopPropagation()" matTooltip="Hide Title">visibility_off</mat-icon>
      <mat-icon class="icon" *ngIf="!widgetMeta.config.title.show" (click)="widgetMeta.config.title.show = true; titleVisibilityChanged(); $event.stopPropagation()" matTooltip="Show Title">visibility</mat-icon>
    </div>
  </ng-container>
  <ng-template #elseTemplate>
    <mat-form-field class="editHeadingBlock" style="width: 100%">
      <mat-label>Change Title</mat-label>
      <input matInput #newHeading type="text" [value]="widgetMeta.config.title.value" (click)="$event.stopPropagation()">
      <mat-icon matSuffix class="icon saveHeadingIcon" (click)="editingHeading = false; saveNewTitle(newHeading); $event.stopPropagation()">done</mat-icon>
    </mat-form-field>
  </ng-template>
</div>

<div *ngIf="!builderMode">
  <div 
    *ngIf="widgetMeta.config.title.show" 
    [ngStyle]="styles" fxLayout="row wrap" 
    [style.justify-content]="widgetMeta?.config?.alignment?.value"
  >
    {{ widgetMeta.config.title.value }}
  </div>
</div>
