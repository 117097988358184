import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { Subject } from 'rxjs';
import { MetaService } from 'src/app/bloom/services/meta-service';
import { BaseWidgetComponent } from '../base-widget/base-widget.component';
import { ResourcePermissionService } from 'src/app/shared/services/resource-permission.service';
import { PageService } from 'src/app/bloom/services/page-service.service';

@Component({
  selector: 'app-star',
  templateUrl: './star.component.html',
  styleUrls: ['./star.component.css']
})
export class StarComponent extends BaseWidgetComponent implements OnInit, OnChanges {

  contextMenuActions: any;
  rating: any = 0;
  emptyStars: any = 0;
  stars: any = []
  editingHeading: boolean = false;
  textEditMode: boolean = false;
  hoveredNow: boolean = false;
  private destroy:any = new Subject();

  constructor(public metaService: MetaService,
    public pageService: PageService,
    public resourcePermissionService: ResourcePermissionService
  ) {
    super(metaService, pageService, resourcePermissionService)

  }

  ngOnInit(): void {
    super.ngOnInit()
    this.destroy = this.metaService.$contextChanged.subscribe((contextActions: any) => {
      if(contextActions && this.widgetMeta.id == contextActions?.widgetId){
        this.action(contextActions)
      }
    })
  }

  ngOnDestroy(): void {
    this.destroy.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.contextActions?.currentValue){
      this.action(changes.contextActions.currentValue)
    }
    if(changes.widgetMeta?.currentValue){
      this.setContextActions()
      this.calculateStars()
    }
  }

  setContextActions(){
    this.contextMenuActions = {
      actions: [
        "bold",
        "underline",
        "color",
        "backgroundColor",
        "italic",
        "fontSize",
        "fontFamily",
        "edit",
      ]
    }
    this.raiseContextMenuActions.emit(this.contextMenuActions)
  }

  action(event) {
    console.log("action is", event)
    switch (event.actionType) {
      case "delete":
        this.onDelete();
        break;
      case 'updateStyles':
        if (event?.data) {
          this.widgetMeta = event.data;
          super.generateStyles();
          this.newWidgetMeta.emit(this.widgetMeta)
        }
        break;

      default:
        break;
    }
  }

  /**
   * changes the header text
   * @param newHeaderRef reference to the input field that will hold the new header text
   */
  saveNewHeading(newHeaderRef: any) {
    if (newHeaderRef.value == '') {
      console.log("emoty text")
      //show snackbar
      return
    }
    console.log("new title will be saved", newHeaderRef.value)
    this.widgetMeta.config.title.value = newHeaderRef.value

    this.newWidgetMeta.emit(this.widgetMeta)
  }

  toggleHeadingView(status: boolean) {
    this.widgetMeta.config.showTitle.value = status
    this.newWidgetMeta.emit(this.widgetMeta)
  }

  onClick(event: any) {
    this.selectedWidgetId = this.widgetMeta.id
    console.log("icon clicked", this.widgetMeta.id)
    this.widgetSelection.emit(this.widgetMeta.id)
  }

  onDelete() {
    console.log("widget ID", this.widgetMeta.id, "will be deleted")
    this.widgetDeletion.emit(this.widgetMeta.id)
  }

  calculateStars() {
    console.log("star count", JSON.parse(JSON.stringify(this.widgetMeta.config.starCount.value)))
    this.stars = []
    for (let i = 1; i <= this.widgetMeta.config.starCount.value; i++) {
      this.stars.push(i <= this.widgetMeta.config.starRating.value ? 1 : 0)
    }
  }

  starred(starRating: number) {
    if(this.widgetMeta.config?.viewOnly?.value) {
      return
    }
    console.log("starred hit", starRating)
    this.widgetMeta.config.starRating.value = starRating
    this.calculateStars();
    this.userInputDetected(starRating);
    if(this.builderMode) {
      this.newWidgetMeta.next(this.widgetMeta)
    }
  }

  userInputDetected(starValue: number) {
    console.log("input change detected", starValue);
    let userInput: any = {
      dataBindConfig: this.widgetMeta?.dataBindConfig,
      widgetId: this.widgetMeta.id,
      value: starValue
    }
    this.userInputReceived.emit(userInput);
  }
}
