import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, HostListener, Inject, Input, OnInit, PLATFORM_ID } from '@angular/core';
import { PageService } from 'src/app/bloom/services/page-service.service';
import { FormService } from '../form.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'form-canvas',
  templateUrl: './form-canvas.component.html',
  styleUrls: ['./form-canvas.component.css']
})
export class FormCanvasComponent implements OnInit {
  imageCopied: boolean = false;
  // @Input() pageNames = []
  // @Input() formCode = '';
  private reader: FileReader = new FileReader();
  copiedFile:any;
  isBrowser: boolean;
  routeParameterSubscription: any;
  formCode: any;
  @Input() type: any;
  pageSelectSubscription: any;

  constructor(public pageService: PageService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    public formService: FormService
    ) {
      this.isBrowser = isPlatformBrowser(platformId);
      if(!this.isBrowser) return;
    this.document.addEventListener("paste", async(e) => {
      if (!e.clipboardData.files.length) {
        return;
      }
      const file = e.clipboardData.files[0];
      if(this.pageService.isPointerInCanvas && this.pageService.isPastedOnce){
        this.imageCopied = true;
        this.copiedFile = file;
        // this.pageService.createCopiedContent(file, "image");
      }
    })
  }
  // comment

  ngOnInit(): void {
    this.routeParameterSubscription = this.route.params.subscribe((routeData) => {
      console.log('route data received: ', routeData);
    });
    // this.pageSelectSubscription = this.formService.pageSelectionRequest.subscribe(async (page) => {
    //   console.log("pageSelectionRequest", page);
    // })
    // document.addEventListener("paste", this.pasteFromListener.bind(this))
   }

  ngOnDestroy() {
    this.pageSelectSubscription?.unsubscribe();
    // document.removeEventListener('paste', this.pasteFromListener.bind(this));
  }

  mouseover(){
    this.pageService.isPointerInCanvas = true;
  }

  mouseout(){
    this.pageService.isPointerInCanvas = false;
    // this.pageService.resetCopiedData()
  }

  @HostListener('window:keydown',['$event'])
  async onKeyPress($event: KeyboardEvent) {
    if(($event.ctrlKey || $event.metaKey) && $event.key == "c" && !$event.repeat){
      console.log('CTRL + C', $event.repeat);
      this.imageCopied = false;
      if(this.pageService.isPointerInCanvas) {
        this.pageService.copyWidget();
        this.pageService.isPastedOnce = false;
        this.pageService.isToolCutted = false;
      }
    }

    if(($event.ctrlKey || $event.metaKey) && $event.key == "x" && !$event.repeat){
      console.log('CTRL + x', $event.repeat);
      this.imageCopied = false;
      this.pageService.isPastedOnce = false;
      if(this.pageService.isPointerInCanvas) this.pageService.cutWidget();
    }

    if(($event.ctrlKey || $event.metaKey) && $event.key == "v" && !$event.repeat){

      let content = await navigator.clipboard.readText();
      if(this.pageService.isPointerInCanvas && this.pageService.isPastedOnce && content){
        this.imageCopied = false;
        this.pageService.createCopiedContent(content);
        console.log("in coppied clip")
      } else if(this.pageService.isPointerInCanvas && !this.imageCopied) {
        this.imageCopied = false;
        this.pageService.pasteWidget();
      } else if(this.imageCopied && this.copiedFile){
        this.pageService.createCopiedContent(this.copiedFile, "image");
        this.copiedFile = null;
      }
    }
  }


}
